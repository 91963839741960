import { inject } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { ReportResponse } from '@modules/sel/reportpdf.service';
const ERRORS_DEFAULT: ValidationErrors = {
	required: 'Este campo es requerido',
	email: 'Ingrese un email válido'
};

export const getFormControlError = (formControl: AbstractControl, translocoService: TranslocoService): string => {

	if (!formControl.errors) return '';
	const errorSection = 'error-message';

	const firstErrorKey = Object.keys(formControl.errors!)[0];
	if (formControl.errors[firstErrorKey] === true) {
		return translocoService.translate(`${errorSection}.${firstErrorKey}`);
	}

	let error = '';
	switch (firstErrorKey) {
		case 'minlength':
		case 'maxlength':
			error = translocoService.translate(`${errorSection}.${firstErrorKey}`, { value: formControl.errors[firstErrorKey].requiredLength });
			break;
		case 'max':
			error = translocoService.translate(`${errorSection}.${firstErrorKey}`, { value: formControl.errors[firstErrorKey].requiredLength });
			break;
		case 'min':
			error = translocoService.translate(`${errorSection}.${firstErrorKey}`, { value: formControl.errors[firstErrorKey].requiredLength });
			break;
		default:
			error = ERRORS_DEFAULT[firstErrorKey] || '';
	}

	return error;
};

export const GetExcelFromBlob = (data: Blob, name: string) => {
	const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
	const url = window.URL.createObjectURL(blob);
	const a = document.createElement('a');
	a.href = url;
	a.download = `${name}.xlsx`;
	a.click();
	window.URL.revokeObjectURL(url);
}


export const convertToMarkDowns = (messageString: string) => {
	var result = replaceWithBold(messageString);
	result = replaceWithStriketrough(result);
	result = replaceWithItalics(result);
	result = replaceWithBreakLine(result);
	return result;
}

const replaceWithBold = (input: string) => {
	// var pattern = '\*\*(.*?)\*\*';
	// var pattern = "\\*\\*(.*?)\\*\\*";
	var pattern: RegExp = /\*\*(.*?)\*\*/g;
	var replacement = '<b>$1</b>';

	var output = input.replace(pattern, replacement);

	return output;
}


const replaceWithStriketrough = (input: string) => {
	// var pattern = '~(.*?)~';
	// var pattern = "/~(.*?)~/g";
	var pattern: RegExp = /~(.*?)~/g;

	var replacement = '<s>$1</s>';

	var output = input.replace(pattern, replacement);

	return output;
}

const replaceWithItalics = (input: string) => {
	var pattern: RegExp = /__(.*?)__/g;
	var replacement = '<em>$1</em>';

	var output = input.replace(pattern, replacement);

	return output;
}





const replaceWithBreakLine = (input: string) => {
	// var pattern = '\n';
	// var pattern = "/\n/g";
	var pattern: RegExp = /\n/g;

	var replacement = '<br>';

	var output = input.replace(pattern, replacement);

	return output;
}


export const waitFromMilliseconds = (milliSeconds: number) => {
	return new Promise<void>((resolve) => {
		setTimeout(() => {
			resolve();
		}, milliSeconds);
	});
}

export const downloadPdf = (response: ReportResponse) => {
	// 1. Decodificar Base64 a un array de bytes
	const linkSource = `data:application/pdf;base64,${response.reportBytes}`;
	const downloadLink = document.createElement("a");
	const fileName = `${response.reportName}`;
	downloadLink.href = linkSource;
	downloadLink.download = fileName;
	downloadLink.click();
	//remove linkSource
	window.URL.revokeObjectURL(linkSource);
}