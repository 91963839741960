import { ComponentRef, Directive, ElementRef, OnDestroy, OnInit, ViewContainerRef, inject } from '@angular/core';
import { NgControl } from '@angular/forms';
import { EMPTY, Subject, fromEvent, merge, takeUntil } from 'rxjs';
import { ControlErrorComponent } from '../components/controlerror/control-error.component';
import { getFormControlError } from '../utils/functions';
import { FormSubmitDirective } from './form-submit.directive';
import { TranslocoService } from '@jsverse/transloco';

@Directive({
    selector: '[formControl], [formControlName]',
    standalone: true
})
export class ControlErrorsDirective implements OnInit, OnDestroy {
    private readonly ngControl = inject(NgControl);
    private readonly form = inject(FormSubmitDirective, { optional: true });
    private readonly destroy$ = new Subject<void>();
    private readonly elementRef: ElementRef<HTMLElement> = inject(ElementRef);
    private readonly vcr = inject(ViewContainerRef);

    private componentRef!: ComponentRef<ControlErrorComponent>;
    private translocoService = inject(TranslocoService);
    private readonly submit$ = this.form ? this.form.submit$ : EMPTY;
    private readonly blurEvent$ = fromEvent(this.elementRef.nativeElement, 'blur');

    ngOnInit(): void {
        merge(this.submit$)
            .pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
                if (!this.ngControl.control) {
                    return;
                }
                const errorControl = getFormControlError(this.ngControl.control!, this.translocoService);
                this.setError(errorControl);

            });

        merge(this.blurEvent$, this.ngControl.statusChanges!)
            .pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
                if (!this.ngControl.control || !this.ngControl.touched || this.ngControl.control.errors == null) {
                    if (this.componentRef) {
                        this.componentRef.instance.error = '';
                    }
                    return;
                }
                const errorControl = getFormControlError(this.ngControl.control!, this.translocoService);
                this.setError(errorControl);

            });
    }

    setError(text: string) {
        this.ngControl.control?.markAsDirty();
        if (!this.componentRef) {
            this.componentRef = this.vcr.createComponent(ControlErrorComponent);
        }
        this.componentRef.instance.error = text;
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}